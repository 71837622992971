<template>
  <v-list-item
    @click="openModal"
    >
    <product-detail
      v-if="modal"
      @close="modal = false"
      :productId="result.id"
      ref="productDetail"
      ></product-detail>

    <v-list-item-avatar
      rounded="lg"
      size="50"
      >
      <v-img
        v-if="result.images.length"
        :src="url + result.images[0].thumbUrl"
        >
      </v-img>
    </v-list-item-avatar>
    <v-list-item-content>
      <v-list-item-title v-text="result.name"></v-list-item-title>
      <v-list-item-subtitle v-text="result.description"></v-list-item-subtitle>
      <v-list-item-subtitle v-text="result.store.name"></v-list-item-subtitle>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
import { mapGetters } from 'vuex'

const ProductDetail = () => import('@/components/products/Product')

export default {
  data () {
    return {
      modal: false
    }
  },

  props: {
    result: {
      type: Object,
      required: true
    }
  },

  computed: {
    ...mapGetters(['url', 'currentUser'])
  },

  methods: {
    openModal () {
      this.modal = true
      setTimeout( () => {
        this.$refs.productDetail.dialog = true
      }, 100)
    }
  },

  components: {
    ProductDetail
  }
}
</script>
